<template>
  <div>
    <v-checkbox
      class="mt-2"
      v-model="insertFile"
      :label="insertLabel"
      :disabled="!enableCommandInsert"
      @change="onInsertOptionChanged()"
      :hide-details="isCompoundFile"
    ></v-checkbox>

    <div class="mt-0 col-4 ml-8">
      <v-select
        dense
        v-show="!isCompoundFile"
        :disabled="!enableSelectPage"
        @change="onCustomSelectedPageChange"
        v-model="customSelectedPage"
        :items="compoundFileList"
        label="Page"
        :prepend-icon="iconPage"
      >
      </v-select>
    </div>

    <v-checkbox
      v-show="isCompoundFile"
      class="mt-0"
      v-model="overwriteFile"
      :label="overwriteLabel"
      @change="onOverwriteChange"
      hide-details
    ></v-checkbox>
  </div>
</template>

<script>
import { isCompoundFile } from "@/services/record/recordService";
import { iconPage } from "@/design/icon/iconConst";
import { eventNames } from "@/model/common/events/eventConst";

export default {
  name: "CompoundInsertOptions",
  props: {
    record: {
      type: Object,
      required: true
    },
    siblingsCount: {
      type: Number
    },
    defaultPage: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      customSelectedPage: this.defaultPage,
      insertFile: false,
      overwriteFile: false,
      iconPage: iconPage
    };
  },
  computed: {
    /**
     * validate if select Page option should be enabled
     * @return {Boolean}
     */
    enableSelectPage() {
      return this.insertFile;
    },

    /**
     * validate if Insert Option should be enabled
     * @return {Boolean}
     */
    enableCommandInsert() {
      return this.isCompoundFile ? true : this.compoundFileList.length !== 0;
    },

    /**
     * Compute if Record is Compound File/Child
     * @return {Boolean}
     */
    isCompoundFile() {
      return isCompoundFile(this.record);
    },

    /**
     * Get Total Files(child) in Array of Compound Document
     * @return {Array}
     */
    compoundFileList() {
      return Array.from({ length: this.siblingsCount ?? -1 }, (_, i) => i + 1);
    },

    /**
     * Get Overwrite Command Label
     * @return {string}
     */
    overwriteLabel() {
      return `Overwrite Page ${this.defaultPage}`;
    },

    /**
     * Insert Command Label
     * @return {string|string}
     */
    insertLabel() {
      return this.isCompoundFile
        ? `Insert Before Page ${this.customSelectedPage}`
        : `Insert Before Page`;
    }
  },
  methods: {
    /**
     * On Selected Page Change Event handler
     * For Compound Document Upload
     * @param {Number|number} page
     */
    onCustomSelectedPageChange(page) {
      try {
        console.log(
          `${this.$options.name} onCustomSelectedPageChange() PageSelected:`,
          page
        );
        this.customSelectedPage = page;
        this.overwriteFile = false;
        this.emitEvent();
      } catch (e) {
        console.error(e);
      }
    },

    /**
     * Event to update option data when insert option changed
     */
    onInsertOptionChanged() {
      if (!this.insertFile) this.customSelectedPage = this.defaultPage;
      else this.overwriteFile = false;
      this.emitEvent();
    },

    /**
     * Event to update option data when overwrite option changed
     */
    onOverwriteChange() {
      if (this.overwriteFile) this.insertFile = false;
      this.emitEvent();
    },

    /**
     * Emit Events to Parent
     */
    emitEvent() {
      this.$emit(eventNames.customPage, this.customSelectedPage);
      this.$emit(eventNames.insertFile, this.insertFile);
      this.$emit(eventNames.overwriteFile, this.overwriteFile);
    }
  },
  watch: {
    defaultPage() {
      this.customSelectedPage = this.defaultPage;
      this.emitEvent();
    }
  }
};
</script>

<style scoped></style>
